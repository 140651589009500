import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the All In Calisthenics Programs. I get shredded before I expected and achieve my first Muscle Up!",
    name: 'MANUEL BERON',
    status : 'ATHLETE',
  },
  {
    image: image2,
    review: ' Very happy with my progress and with the dynamic routines!',
    name: 'MAITE SARASOLA',
    status: 'ATHLETE',
  },
  {
    image : image3,
    review:'Thanks to All In Calisthenics for accompanying me on this journey to get the body I wanted.',
    name: 'JERONIMO KRISTOF',
    status: "ATHLETE",
  }
];
